<template>
  <div class="gap-4 p-4">
    <Card style="max-width: calc(100vw - 3rem)">
      <template #title> Confirmation </template>
      <template #subtitle> Vérifiez vos informations </template>
      <template #content>
        <div
          class="flex justify-content-between align-items-end p-field col-12"
        >
          <label>Prénom</label>
          <b>{{ formData.firstname ? formData.firstname : "-" }}</b>
        </div>
        <div
          class="flex justify-content-between align-items-end p-field col-12"
        >
          <label>Nom</label>
          <b>{{ formData.firstname ? formData.lastname : "-" }}</b>
        </div>
        <div
          class="flex justify-content-between align-items-end p-field col-12"
        >
          <label>Adresse email</label>
          <b>{{ formData.email ? formData.email : "-" }}</b>
        </div>
        <div class="flex column p-field col-12">
          <label>Etablissements</label>
          <div class="mt-2">
            <Chip
              :label="centers[center].name"
              v-for="(key, center) in formData.centers"
              :key="center"
            ></Chip>
          </div>
        </div>
        <div
          class="flex justify-content-between align-items-end p-field col-12"
        >
          <label>Poste occupé</label>
          <b>{{ formData.role ? computedRoles[formData.role] : "-" }}</b>
        </div>
      </template>
      <template #footer>
        <hr />
        <div class="flex justify-content-between">
          <Button
            label="Précédent"
            class="p-button-sm p-button-text p-button-secondary"
            @click="prevPage"
          />
          <Button
            label="Créer un compte"
            class="p-button-sm p-button-success"
            @click="complete"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { computed } from "vue";
import useDatabase from "../../../composables/useDatabase";

export default {
  props: ["formData", "roles"],
  setup(props, { emit }) {
    const pageIndex = 3;
    const prevPage = () => emit("prev-page", { pageIndex });
    const complete = () => emit("complete");

    const computedRoles = computed(() => {
      let result = {};
      props.roles.forEach((r) => {
        result[r.value] = r.name;
      });
      return result;
    });

    const { mapAll } = useDatabase("centers");
    const { docs: centers, error, pending } = mapAll();

    return {
      prevPage,
      complete,
      centers,
      error,
      pending,
      computedRoles,
    };
  },
};
</script>

<style lang="scss" scoped>
.p-field {
  margin-bottom: 0.75rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.p-chip {
  font-size: 0.75rem;
  margin-right: 0.25rem;
  &:last-child {
    margin-right: 0;
  }
}
</style>
